*{
    margin: 0;
    padding: 0; 
    box-sizing: border-box;
    
}
.ProductSections{
    margin: 2rem auto;
    width: 90vw;
    background-color: white;
}
.grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
    gap:1rem;
    font-family: sans-serif;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.grid div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}
.grid div img{
    width: 100%;
    height: 100%;
    min-width: 151.15px;
    min-height: 151.15px;
    object-fit: cover;
    border-radius: 4px;
}
.lower-flex{
    display: flex;
    width: 97%;
    margin: auto;
    overflow: scroll;
}
.lower-flex img{
    width: 75px;
    height: 75px;
    border-radius: 5px;
    margin: .5rem;
}
.grid-2{
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.grid-carousel{
    margin: 1rem auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.grid-carousel img{
    margin: auto;
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.grid-carousel .discount{
    width: 60px;
    text-align: center;
    color: white;
    background-color: #cc0c39;
    border-radius: 2px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 10px;
    padding: 4px 6px;
    font-size: 12px;
    font-family: sans-serif;
}
.grid-carousel .product-info{
    margin-top: 5px;
    font-size: 1.3rem;
    font-family: sans-serif;
    color: #0f1111;
}
.grid-carousel .product-info .strike{
    font-size: .8rem;
    color: #565959;
}
/* Skeleton Loader */
.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }