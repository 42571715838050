/* 

.imageContainer{

 padding: 26px;
background-color: white;

border-radius: 4%;
display:flex;
width: 50%;
margin-top: 50px;
margin-bottom: 00px;
flex-direction: column;
align-items: center;

}

.imageContainer img{
    flex: 1;
     height: 100%;
  
    padding-left: 20px;
    padding-right:20px;
}
.social-share-footer{
    background: #fff;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
     display: flex;
    flex-direction: column; 
     
     width: 100%;
     height: 100%;

}
.networks{
    display: flex;
   
    list-style-type: none;
   
    padding: 0px 0px;
}
.share-networks{
    width: 23%;
    height: 90px;
    margin: 10px 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.share-networks img{
    height: 45px;
    width: 45px;
}
.share-networks span{
    font-size: 12px;
    color: rgb(60, 59, 59);
} */

.topopenbody{
    height: 100vh;
    width: 100vw;
     display: flex; 
    flex-direction: column; 
  position: absolute;
  z-index: 500; 
   /* margin-top: -767px;  */
    background-color: rgb(62, 61, 61);}

 

.topSideBox{
   
    flex: 8;
    
}
.boxContainer{
    background-color: #fff;
    height: 250px;
    margin-top: 40%;
    width: 200px;
    border-radius: 5%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.image{
    padding: 5px 10px;
    flex: 8;
}
image img{
    width: 100%;
    height: 100%;
    
}

.imagedesc{
  
    flex: 2;
    font-size: 15px;
    font-weight: 500;
}


.bottomSideBox{
    
    flex: 4;
}
.bottomContainer{
    background-color: #fff;
    width: 100vw; 
     height: 100%; 
     opacity: 2;
     border-top-left-radius: 20%;
    border-top-right-radius: 20%;
      padding:38px 10px; 
}
.Headers{
display: flex;
/* margin: 0px 20px; */
/* padding: 0px 24px; */
justify-content: center;
flex-wrap: wrap;
gap: 5px;
align-items: center;
}
.shareNetworks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
      margin: 20px 15px;
      width: 67.3px;
}
.shareNetworks img{
width: 30px;
height: 30px;
}
.shareNetworks span{
    font-size: 15px;
    color: gray;
    margin-top: 5px;
}


  .exitbutton{
    display: flex;
    align-items:center ;
    margin-left: 35%;
    margin-top: 10%;
 
   
   
  }

  .exitbutton button{
    letter-spacing: 4px;
    font-weight: 800;
    border: .5px solid gray;
    border-radius: 5px;
    padding: 5px 15px;
  }
@media (max-width:380px){
    .exitbutton{
        transform: translateY(0%);
    }
.bottomContainer{
    padding:0px 10px; 
}
.exitbutton{
    padding-bottom: 10px;
}
    
} 