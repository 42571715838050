.listHolderMobile{
    margin-left: auto;
    padding: 0!important;
}
/* css for the mobile view navbar  */
.logoS {
    float: left;
    /* margin-left: 5vw; */
    height: 40px;
    width: 40px;
}
.logoAvatarContainerS {
    margin: 1vh 1vh 0 0;
}

.navbarMobileViewS {
    height: 8vh;

}

.companyName {
    color: teal;
    font-size: 1.75em;
    font-weight: 700;
    letter-spacing: 0.5px;
}

/* css for the chat button in sidemenu  */

.chatOffcanvsNavbar {
    font-size: 30px;
    background: teal;
    width: fit-content;
    height: fit-content;
    color: aliceblue;
    margin-left: 0px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 100%;
}


/* css of the login/signup modal in mobile view  */

.loginPageMobile {
    background: white;
    height: fit-content;
    width: 95%;
    margin-top: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* support css for the login/signup modal  */

.collageModalMobile {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.255);
    z-index: 1400;
    top: 0;
}

.logoSNavbar {
    height: 45px;
    margin-left: -3%;
}

.sideMenuS {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    /* margin-top: 50%; */
    margin-left: 12%;
    margin-right: 12%;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    z-index: 1200;
    margin-top: -7%;
}

@media (min-width: 540px) and (max-width: 800px) {
    .sideMenuS {
        margin-top: -4%;
    }

    .crossButtonHolder {
        margin-left: 62%;
    }
}

@media (min-width: 380px) and (max-width: 400px) {
    .crossButtonHolder {
        margin-left: 45%;
    }
}
@media (min-width: 330px) and (max-width: 379px) {
    .crossButtonHolder {
        margin-left: 35%;
    }
}
@media (min-width: 300px) and (max-width: 329px) {
    .crossButtonHolder {
        margin-left: 30%;
    }
}

@media (min-width: 401px) and (max-width: 450px) {
    .crossButtonHolder {
        margin-left:48%;
    }
}
@media (min-width: 451px) and (max-width: 539px) {
    .crossButtonHolder {
        margin-left:55%;
    }
}

.userDisplayNameS {
    padding: 2px 4px;
    border-radius: 5px;
    border: 1px solid teal;
    font-family: 'Mulish';
    font-weight: 600;
    letter-spacing: 1px;
    color: teal;
}

.displayPictureS {
    height: auto;
    width: max-content;
}

.logInSignUpButton {
    color: teal;
    font-weight: 500;
    font-family: 'Mulish';
    letter-spacing: 1px;
}

.logoutButtonNavbarMobileS {
    color: teal;
    letter-spacing: 1px;
    font-family: 'Mulish';
    font-weight: 500;
}

.navbarDesignS {
    background: rgb(243, 243, 243);
    display: flex;
    box-shadow: rgba(33, 35, 38, 0.336) 0px 10px 10px -10px;
    align-items: center;
    /* backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px); */
    /* border-radius: 10px; */
    border: 1px solid rgba(255, 255, 255, 0.18);
    /* margin: 0.25em auto; */
}

.dropDownMenu{
    display: block;
    background-color: white;
    width: fit-content;
    border-radius: 10px;
    position: static;
    position: absolute;
    overflow: hidden;
    top:3rem;
    border-radius: 10px;
    right: 0;
    z-index: 1300;
    transition-duration: 0.5s;
}
.logoutButtonNavbarMobileS {
    color: teal;
    letter-spacing: 1px;
    font-family: 'Mulish';
    font-weight: 500;
}
.listed-item a{
    text-decoration: none;
    color: black;
}
.listed-item a:focus{
    color: rgb(3, 216, 216);
}