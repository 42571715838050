.verficationForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    height: 20vh;
    width: 95%;
    margin:0 auto;
    cursor: pointer;
    border-radius: 10px;
}
.verifyUploadBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0 auto ;
    background-color: rgb(0, 221, 166);
    color:black;
    margin-top: 10px;
    padding: 2px 10px;
    border-radius:20px ;
    box-shadow: 0 0 8px rgba(3, 10, 62, 0.611);
}
.filesContainer{
    background-color: antiquewhite;
    border-radius: 5px;
    margin:0 10px;
    padding: 3px 0;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.fa-cloud-arrow-up{
    font-size: xx-large;
}
.verificationConatainer{
    width: 100%;
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.OrInVarefiction{
    font-size: 2rem;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
}
.logInSignUpBtn{
    background: linear-gradient(45deg,#178d85,#4fbcc0);
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    border-radius: 10px;
}
canvas{
    margin : auto;
}