.bottomNavigationContanier{
    width: 100vw;
    height: 4rem;
    background: #127d7a;
    display: flex;
    justify-content: center;
    padding: 30px 0 10px 0;
    align-items: center;
    position: fixed;
    bottom: -1px;
    z-index: 1024;
}
.bottomNavigation{
    display: flex;
    width: 90vw;
    padding-left:0 !important ;
}
.bottomNavigation li{
    position: relative;
    list-style: none;
    width: 18vw;
    z-index: 1;
}
.bottomNavigationLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.bottomNavigationLink span{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    margin-right: 0;
}
.bottomNavigationLink span i{
    display: block;
    position: relative;
    line-height: 75px;
    font-size: 1.5rem;
    text-align: center;
    color: rgb(255, 254, 254);
    transition: 0.5s ease-in-out;
    padding-left: 0;
    transform: translateY(0px);
    margin-right: 0;
}

.bottomNavigationName{
    position: absolute;
    font-size: 0.8rem;
    letter-spacing: .05em;
    transition: 0.5s;
    margin-top: 55px;
    font-weight: 500;
    color: black;
    opacity: 0;
    /* transform: translateY(10px); */
}
.ActiveIcon{
    /* transform: translateY(-15%); */
    transition: 0.5s ease-in-out;
}
.ActiveIcon i{
    color: rgb(0, 0, 0);
}

.ActiveName{
    opacity: 1;
}
.bottomNavigationBackground{
    position: absolute;
    width: 18vw;
    height: 18vw;
    background: rgb(0, 0, 0);
    border-radius: 100%;
    /* border:6px solid #ffffff00; */
    z-index: -1;
    transform: scale(0);
    /* transform: translateY(-15%); */
    transition-duration: 0.5s;
    /* left: calc(5vw* 1); */
}
.activeBackground{
    transform: scale(0.6);
    position: absolute;
    width: 21vw;
    height: 21vw;
    background: rgb(0, 0, 0);
    border-radius: 100%;
    border:6px solid #ffffff00;
    z-index: -1;
    /* transform: translateY(-15%); */
    transition-duration: 0.5s;
    /* left: calc(5vw* 1); */
}
.bottomNavigationBackgroundTeal{
    position: absolute;
    width: 18vw;
    height: 18vw;
    background: rgb(0, 0, 0);
    border-radius: 100%;
    /* top: -47%; */
    transition-duration: 0.5s;
    border:6px solid #226f54;
}
.bottomNavigationBackgroundTeal::before{
    content: "";
    position: absolute;
    /* top: 50%;
    left: -39%; */
    box-shadow: 0 -7px #226f5400;
    width: 20px;
    height: 20px;
    border-top-right-radius: 20px;
    background: transparent;
}
.bottomNavigationBackgroundTeal::after{
    content: "";
    position: absolute;
    /* top: 50%;
    right: -39%; */
    box-shadow: 0 -7px #226f5400;
    width: 20px;
    height: 20px;
    border-top-left-radius: 20px;
    background: transparent;
}
.bottomNavigationBackground::before{
    content: "";
    position: absolute;
    /* top: 50%;
    left: -39%; */
    box-shadow: 0 -7px #ffffff00;
    width: 20px;
    height: 20px;
    border-top-right-radius: 20px;
    background: transparent;
}
.bottomNavigationBackground::after{
    content: "";
    position: absolute;
    /* top: 50%;
    right: -39%; */
    box-shadow: 0 -7px #ffffff00;
    width: 20px;
    height: 20px;
    border-top-left-radius: 20px;
    background: transparent;
}
.translatet-x-0{
    transform: translate(calc(18vw * 0));
}
.translatet-x-16{
    transform: translate(calc(18vw * 1));
}
.translatet-x-32{
    transform: translate(calc(18vw * 2));
}
.translatet-x-48{
    transform: translate(calc(18vw * 3));
}
.translatet-x-64{
    transform: translate(calc(18vw * 4));
} 