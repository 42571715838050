@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
*
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
}


body
{
    font-family: 'Roboto', sans-serif;
}
a
{
    text-decoration: none;
}
.product-card {
    width: 95%;
    height: 100%;
    position: relative;
    box-shadow: 0 2px 7px #7b7b7b9e;
    margin: 20px 10px;
    background: #ffffff;
    overflow: hidden;
    border-radius: 10px;
}

.badge {
    position: absolute;
    left: 0;
    top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    background: red;
    color: #fff;
    padding: 3px 10px;
}

.product-tumb {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    padding: 10px;
    background: #ffffff;
    border-radius: 10px 10px 0 0;
}

.product-tumb img {
    margin-top: 8.5em;
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.product-details {
    margin-top: 40%;
    padding: 20px;
    border-radius: 5px;
}

.product-catagory {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: 18px;
}

.product-details h4 a {
    font-weight: 600;
    display: block;
    margin-bottom: 18px;
    text-transform: uppercase;
    color: #010522;
    text-decoration: none;
    transition: 0.3s;
}

.product-details h4 a:hover {
    color: #fbb72c;
}

.product-details p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 18px;
    color: #999;
}

.product-bottom-details {
    overflow: hidden;
    border-top: 1px solid #eee;
    padding-top: 5px;
}

.product-bottom-details div {
    float: left;
    width: 50%;
}

.product-price {
    font-size: 18px;
    color:teal;
    font-weight: 600;
}

.product-price small {
    font-size: 80%;
    font-weight: 400;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 5px;
}

.product-links {
    text-align: right;
}

.product-links a {
    display: inline-block;
    margin-left: 5px;
    color: #e1e1e1;
    transition: 0.3s;
    font-size: 20px;
}

.product-links a:active {
    color: teal;
}
.btn{
    background-color: rgb(6, 195, 195);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 15px;
    
   
}
.btn:hover{
    background-color: teal;

}
.btn button{
    letter-spacing: 2px;
    font-weight: 700px;
    color:white
}
.headContainer{
    top: 500px;
    height: 56vh;
}
.No-Products{
    width: 80vw;
    margin: 2rem auto;
}
.No-Products h1{
    text-align: center;
}

@media (max-height:750px) and (min-height:650px)
{
    .headContainer{
        height:67vh;
    }  

}