.slide{
    width: 80%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
}
.carousel-inner{
    border-radius: 10px;
    width: 100%;
}
.carousel-item img{
    width: 40rem;
    height: 30rem;
}
@media (max-width:994px) {
    .slide{
        width: 99%;
        height: 50%;
    }
}
@media (max-width:460px) {
    .slide{
        width: 95%;
        border-radius: 10px;
        /* background-color: rgb(228, 228, 228); */
        overflow: hidden;
    }
    .carousel-inner{
        border-radius: 0;
    }
    .carousel-item img{
        height: 16rem;
    }
    .fa-angles-right{
        width: 20px;
    }

}
#carouselIndicators{
    background-color: rgba(240, 248, 255, 0.819);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius:20px ;
    padding-left:10px ;
    padding-right: 10px;
}
#carouselIndicators button{
    width: 8px;
    height: 8px;
    background-color: rgb(23, 210, 164);
}
.carousel-indicators [data-bs-target]{
    border-radius: 100%;
    background-color: rgb(23, 210, 164);
}
.carousel-control-next, .carousel-control-prev{
    opacity: 1;
    color: black;
}
.carousel-control-prev:hover{
    color: rgb(12, 191, 137);
}
.carousel-control-next:hover{
    color: rgb(12, 191, 137);
}
.fa-chevron-right{
    background-color: rgba(255, 255, 255, 0.601);
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    width: 40px;
    color: black;
    height: 60px;
    opacity: 1;
    margin-left: auto;
}
.fa-chevron-left{
    background-color: rgb(255, 255, 255,0.601);
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    width: 40px;
    height: 60px;
    color: black;
    opacity: 1;
    margin-right: auto;
}
.fa-angles-left:hover{
    color: rgb(0, 0, 0);
}
.carousel-item img{
    object-fit:contain ;
}