.container {
    position: relative;
    /* margin-top: 60px; */
    height: 100px;
}

.item1, .item2, .item3 {
    background-color: transparent;
    left: calc(50% - (var(--size) / 2));
    top: calc(50% - (var(--size) / 2));
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    position: absolute;
  }
  
  .loader .item1 {
    --size: 90px;
    animation: size1_1 2.5s infinite alternate;
    z-index: 1;
  }
  
  .loader .item2 {
    --size: 60px;
    animation: size1_2 2.5s infinite alternate;
    z-index: 2;
  }
  
  .loader .item3 {
    --size: 30px;
    animation: size1_3 2.5s infinite alternate;
    z-index: 3;
  }
  
  @keyframes size1_1 {
    0%,
    10% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
      box-shadow: 8px 8px 14px #c5c5c5, -8px -8px 14px #ffffff;
    }
  }
  
  @keyframes size1_2 {
    0%,
    40% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
      box-shadow: 8px 8px 14px #c5c5c5, -8px -8px 14px #ffffff;
    }
  }
  
  @keyframes size1_3 {
    0%,
    70% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
      box-shadow: 8px 8px 14px #c5c5c5, -8px -8px 14px #ffffff;
    }
  }