@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@400;500;600;700;800;900;1000&display=swap');

.registerpage-Navbar {
    position: sticky;
    top: 0;
    width: 100vw;
    font-size: 1.7rem;
}
.registerpage-Navbar span i{
    margin-left: 10px;
    color: #127d7a;
    font-size: 1.3rem;
    text-decoration: none;
}

.registerpage {
    width: 100vw;
    overflow-y: scroll;
    height: 100vh;
    background-color: #fafafa;
}
.registerdata-Image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerdata-Image-Logo {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: linear-gradient(20deg, #1bbba3, #ffff);
    width: fit-content;
    height: fit-content;
    padding: 15px;
    border-radius: 100%;
}

/* Circel */
.Background-Circle {
    height: 65vmin;
    position: relative;
    width: 65vmin;
}

.Background-Circle div {
    animation: growAndFade 3s infinite ease-out;
    background-color: #1a9d94;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.circle1 {
    animation-delay: .5s;
}

.circle2 {
    animation-delay: .6s;
}

.circle3 {
    animation-delay: 1s;
}

@keyframes growAndFade {
    0% {
        opacity: .25;
        transform: scale(0);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.registerdata-Image div img {
    width: 6rem;
    height: 6rem;

}

.registerpage h1 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0 auto;
    color: #127d7a;
}
.registerform{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.registerform h2 {
    text-align: center;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #127d7a;
}

.registerdata {
    margin-top: auto;
}

.registerSignUp {
    font-size: medium;
    margin-top: 10px;
}

.registerdata button {
    background: linear-gradient(270deg, #178d85, #4fbcc0);
    width: 50vw;
    margin-top: 20px;
    font-size: 1.6rem;
    color: white;
    padding: 5px 15px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;

}

.toggleRegister {
    color: rgb(38, 38, 38);
    font-weight: 100;
    margin-top: 10px;
    font-size: 0.9rem;
    margin-right: auto;
    margin-bottom: 5px;
}

/* Input Box Css for Login*/
.registerdata-InputContainer {
    position: relative;
}

.registerdata-InputContainer input {
    border: solid 1.5px #02c1a1;
    width: 90vw;
    border-radius: 1rem;
    margin-top: 20px;
    background: none;
    padding: 0.8rem;
    font-size: 1rem;
    color: #000000;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.registerdata-InputContainer:nth-child(1)~input {
    margin-top: 0;
}

.user-Lable,
.password-Lable {
    position: absolute;
    left: 15px;
    color: #0c0c0c;
    font-weight: 400;
    pointer-events: none;
    transform: translateY(2rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.registerdata-InputContainer input:focus,
.registerdata-InputContainer input:valid {
    outline: none;
    border: 1.5px solid #005429;
}

.registerdata-InputContainer input:focus ~label,
.registerdata-InputContainer input:valid ~label {
    transform: translateY(36%) scale(0.9);
    background-color: rgb(255, 255, 255);
    padding: 0 .2em;
    color: #000000;
}

/* Input CSS for SignUP */
.signUp-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signUp-input-container {
    position: relative;
}

.signUp-input-container input {
    border: solid 1.5px #02c1a1;
    width: 70vw;
    border-radius: 1rem;
    margin-top: 40px;
    background: none;
    padding: 0.8rem;
    font-size: 1rem;
    color: #000000;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.signUp-Lable{
    position: absolute;
    left: 15px;
    color: #0c0c0c;
    font-weight: 400;
    pointer-events: none;
    transform: translateY(3.5rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.signUp-input-container input:focus,
.signUp-input-container input:valid
{ 
    outline: none;
    border: 1.5px solid #005429;
}
.signUp-input-container input:focus ~.signUp-Lable,
.signUp-input-container input:valid ~.signUp-Lable{
    transform: translateY(115%) scale(0.9);
    background-color: rgb(255, 255, 255);
    padding: 0 .2em;
    color: #000000;
}
.signUp-data .signUp-input-container:nth-child(3) .signUp-Lable{
    transform: translateY(115%) scale(0.9);
    background: #fff;
    padding: 0 5px;
}
.signUp-data span{
    margin-top: 5px;
    font-size: 0.8rem;
}
.signUp-submit{
    background: linear-gradient(270deg, #178d85, #4fbcc0);
    width: 50vw;
    margin-top: 20px;
    font-size: 1.4rem;
    color: white;
    padding: 5px 15px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
}

/* Wa Login CSS */
.WaLogin{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
}
.WaLogin-Image {
    width: fit-content;
    margin: 0 auto;
    position: relative;
}

.WaLogin-Image-background-Teal {
    border-radius: 100%;
    margin: 0 auto;
    position: absolute;
    z-index: -1;
    top: 10%;
    width: 60vw;
    height: 60vw;
    background: #01d1af35;
}

.WaLogin-Image-background img {
    width: 50vw;
    height: 50vh;
}

.WaLogin-Content-Heading {
    display: flex;
    justify-content: center;
}

.WaLogin-Content-Heading span {
    font-size: 2.3rem;
    color: #178d85;
    margin: 0 auto;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.WaLogin-Content-Desc p {
    text-align: center;
    font-size: 0.8rem;
    color: #178d85;
    font-family: 'Montserrat', sans-serif;
}
.WaLogin-Button-Section{
    margin-top: auto;
    margin-bottom: 20px;
}
.WaLogin-Button-Section button,
.WaLogin-Button-Section .LoginBtn
 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: 0 auto;
    width: 90vw;
    border-radius: 10px;
    padding: 10px 5px;
    margin-top: 1rem;
    background: #25d366;
}

.WaLogin-Button-Section .LoginBtn {
    border: 1px solid #178d85;
    background: white;
}

.WaLogin-Button-Section button span img {
    width: 30px;
    height: 30px;
    margin: auto 0 auto 0;
}
.WaLogin-Button-Section .LoginBtn span img {
    width: 30px;
    height: 30px;
    margin: auto 0 auto 0;
}

.WaLogin-Button-Section button p,
.WaLogin-Button-Section .LoginBtn p
 {
    margin-bottom: 0 !important;
    margin: auto 0 auto 5px;
    color: white;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.WaLogin-Button-Section .LoginBtn p {
    color: #178d85;
}
.addCollege{
    border: 1px solid grey;
    margin:  0 auto;
}
.registerdata-loginBox{
    margin-top: auto;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.forgetPassword{
    font-size: medium;
    color: #000;
    margin-top: 5px;
}
.forgetPassword:focus{
    color: #000;
}
.showPassword{
    width: 20px;
    height: 20px;
}