/* this file contains the css for the modal which appears after user logs in  */

.listItems-Container {
  width: 20vw;
  height: 50vh;
  border: .1rem solid rgb(231, 228, 228);
  border-radius: 5%;
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); */
  align-items: center;
  background: white;
  /* position: relative; */
  /* transform: scale(1.02); */
  opacity: calc(1.2);
   justify-content: center;
}
.listItems-Container ul {
  list-style: none;
  padding-left: 0rem;
  display: flex;
  flex-direction: column;
}

 /* .individual-item {
  margin-bottom: 10px;
}  */

.icon-Container img {
  height: 20px;
  width: 20px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.img-name-container {
  display: flex;
  padding-left: 1rem;
 padding-right: 1rem; 
 padding-top: 10px;
 padding-bottom: 10px;
  /* justify-content: center; */
}

.icon-container {
  flex-shrink: 0;
} 
.linker-tag{
  text-decoration: none;
}

.listed-item {
  margin-left: 5px;
  /* padding-bottom: 5px; */
  cursor:pointer;
  font-size: large;
  font-family: 'Mulish', sans-serif;
  font-size: 15px;
  /* position: fixed; */
  align-items: center;
justify-content: center;
display: flex;
color: black;
}
.dropDownMenuContainer{
  position: absolute;
  overflow: hidden;
  right: 2.5rem;
  top: 4.1rem;
  z-index: 1340;
  border-radius: 10px;
  background: rgb(240, 240, 240);
}
.individual-item:hover{
  background: rgb(221, 221, 249);
  transition: 100ms;
}
 /* .listHolder{
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}   */
.listHolder li{
  transition-duration: 0.2s;
}
.listHolder li:hover{
  background-color: rgba(0, 128, 128, 0.471);
}
#logout-section{
  padding-top: 5px;
  /* margin-bottom: 5px; */
 
 
}
.border-line{
  padding-top: 2px;
  border-top: 0.5px solid rgb(204, 200, 200);
  margin-left: 15px;
  margin-right: 15px;
}
     
.userButton{
border: .5px solid rgb(213, 208, 208);
width: max-content;
 margin-bottom: 10px; 
position: sticky;
color: rgb(23, 1, 97);
margin-right: 10px; 
padding: 7px 10px;
font-weight: 600;
font-family: 'Mulish', sans-serif;
border-radius: .5rem;
}
.userButton:hover{
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

}

.drop-downContainer{
  height: 20px;
  width: 15px;
  margin-left: 7px;
  padding-top: 8px;
}

.userName-icon-container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}