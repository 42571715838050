@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* Chat navbar css */
.navbarContanier{
    /* padding-bottom: 1.5rem; */
    height: fit-content;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 12;
    background: #127d7a;
}
.user{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.user span{
    font-weight: 600;
    font-size: large;
    color: rgb(45, 45, 45);
}
.user img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 1px solid black;
    margin-right: 8px;
    margin-left: 10px;
}
.logo{
    font-weight: 700;
    font-size: 2.5rem;
    color: rgb(0, 56, 56);
}
/* Serach bar css */
.search {
    margin: 0 auto;
}

.searchForm {
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchForm input {
    width: 80vw;
    padding: 5px 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 60px;
    border-radius: 40px;
    border: none;
    box-shadow: 0 1.2px 10px rgba(115, 113, 113, 0.441);
    background: rgb(255, 255, 255);
}
.searchForm input:focus-visible{
    outline: none;
}
.searchForm-container{
    padding: 11px;
    background: #127d7a;
    border-radius: 100%;
    position: absolute;
    left: 11%;
}
.searchForm img{
    width: 1.5rem;
    height: 1.5rem;
    /* padding: 20px; */
}
/* Chat inbox css */
.userChat {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: white;
    border-radius: 10px;
    overflow-x: hidden;
    /* border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; */
}
.userChat:hover{
    background: rgba(162, 162, 162, 0.718);
}

.userChat img {
    margin-left: 3px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.281);
}

.chats {
    width: 100vw;
    padding-top: 0.5rem;
    height: -moz-calc(100vh - 118px);
    height: -webkit-calc(100vh - 118px);
    height: -o-calc(100vh - 118px);
    height: calc(100vh - 118px);
    overflow: scroll;
}

.userChatInfo {
    margin-left: 10px;
}
.userChatInfo span{
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.userChatInfo p{
    width: 50vw;
    font-weight: 200;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: grey;
}