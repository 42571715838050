.category-header {
    width: 100%;
    display: flex;
}

.category-back-btn {
    padding-top: 21px;
    padding-bottom: 20px;
    margin-right: 12px;
    margin-left: 12px;
    height: 40px;
}

h3 {
    margin-top: 21px;
}

.category {
    position: sticky;
    background: #fff !important;
    top: 0px;
    z-index: 5;
}

.category-filter {
    display: inline-flex;
    justify-content: space-around;
    overflow: scroll;
    width: 150%;
    text-align: center;
    /* padding-top: 10px ; */
    padding: 16px 0px;
    margin-left: 0px;
    ;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}

.category-back-btn a {
    text-decoration: none;
    color: black;
}

.category {
    overflow: scroll;
    box-shadow: 0px 2px 2px 1px rgb(197, 193, 193);
    position: sticky;
}

.category-filter-image {
    border-radius: 50%;
    width: 19vw;
    height: fit-content;

}

.nonactivecategoires {
    transform: scale(1.5);
}

::-webkit-scrollbar {
    display: none;
}

/* -----------css for the topCategories Section------------ */

.categories-listing-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-padding: 0 10%;
    scroll-snap-type: x mandatory;
    margin-top: 0px;
    margin-left: 0px;
    width: 102%;
    position: sticky;
    left: 0px;
    top: 60px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
    z-index: 100;
    padding-top: 20px;
    background: #fff;
}
.linkTagS:hover{
    color: #000;
}
.imageAndNameBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 5px;
    justify-content: center;
}

.imgBackgrdcolor {
    background-color: #a4c5d5;
    border-radius: 50%;
    height: 66px;
    width: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgContainerHolder {
    height: 45px;
    width: 45px;
    background-size: cover;
    margin-bottom: 6px;
    margin-top: 3px;
}

.imgContainerHolder img {
    height: 100%;
    width: 100%;
    border-radius: 0px;
}

.imageAndNameBox p {
    font-size: 12px;
    font-weight: 400;
    padding-top: 6px;
    color: #8d8c8c;
    justify-content: center;
    margin-right: 1px;
}

.nonactivecategorie {
    background-color: white;
}

.activecategorie {
    transform: scale(1.2);
}
.activeLink{
    border-bottom: 4px solid #127d7a;
    transition: 0.5s ease-in-out;
    color: #000;
}
.noEffcet{
    transform: scale(1);
    color: #000;
}
.noneffectRen {
    height: 100%;
    width: 100%;
    border-radius: 0px;
}

.effectRen {
    transform: scale(1.2);
    transition: all .5s;
    position: relative;
    top: -20%;
}

.categories-listing-container::-webkit-scrollbar {
    display: none;
}

.headers {
    position: fixed;

}

.example::-webkit-scrollbar {
    display: none;
}