.GetStarted{
    width: 100vw;
    height: 100vh;
    overflow:hidden ;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.GetStarted-Image-Corner{
    width: 100vw;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
}
.GetStarted-Image-Corner img{
    width: 50vw;
    height: 40vw;
}
.GetStarted-Image-Piggy{
    width: 80vw;
    height: 40vh;
}
.GetStarted-Image{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* .GetStarted-Image span{
    text-justify: distribute;
    color: #127d7a;
    font-weight: 600;
    font-size: 1.5rem;
} */

.GetStarted-Button {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 10px 50px;
    border-radius:10px ;
    font-weight:200;
    color: white;
    background: linear-gradient(270deg, #178d85,#4fbcc0);
}
.GetStarted-Button a{
    color: #fff;
    font-size: 2rem;
    text-decoration: none;
    font-weight: 500;
    font-family: "Montserrat", "Helvetica Neue";
}
.GetStarted-Carousel-Image-Container {
    display: flex;
    position: relative;
    transition: 0.5s ease-in-out;
}
.GetStarted-Carousel-Image{
    padding-bottom: 40px;
}
.GetStarted-Carousel-Indicator{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.GetStarted-Carousel-Indicator-Icon{
    width: 13px;
    height: 13px;
    margin-top: 0 !important;
    margin-left: 10px;
    background: rgba(0, 128, 128, 0);
    outline:1px solid #178d85;
    border-radius: 100%;
    transition: 0.5s ease-in-out;
}
.GetStarted-Carousel-Indicator-Active{
    background: teal;
}
.GetStarted-Carousel-Content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.GetStarted-Carousel-Content span{
    font-family: "Montserrat", "Helvetica Neue";
    font-size: 1.4rem;
    color: #178d85;
    font-weight: 500;
    transition-duration:1s ease-in ;
}