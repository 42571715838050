.backgroundError{
    background: #00000023;
    height: 100vh;
    width: 100vw;
    position: relative;
    z-index: 1999;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.ErrorPage{
    width: fit-content;
    height: fit-content;
    position: fixed;
    z-index: 2000;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; 
    padding: 20px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ErrorMessage{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ErrorMessage span{
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #127d7a;
}
.ErrorReloadBtn button{
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #127d7a;
    padding: 5px 10px;
    border-radius: 10px;
    color: #127d7a;
    font-size: 1.3rem;
}
.ErrorReloadBtn button div{
    margin: 0 !important;
}
.errorPage{
    height: 100vh;
}

.errroPageMsg{
    text-align: center;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #127d7a;
}
.errorPageHeader img{
    height: 3rem;
    width: 3rem;
    margin: 5px 0 0 5px;
}