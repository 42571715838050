@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.main-container {
    background:#fff;
    padding: 10px;
    height: max-content;
    margin: 0 0 30px 0;
}

.cardsContainerHolder {
    display: flex;
    overflow: scroll;
    scroll-snap-type: x proximity;
    height: auto;
}

.section-heading {
    margin: 10px 10px;
    font-family: 'Montserrat', sans-serif;
    
}
.section-heading h1{
    font-weight: 700;
    color: #127d7a;
}

.cardsContainerHolder::-webkit-scrollbar {
    display: none;
}
.underline-Products{
    width: 100%;
    border-bottom: 1px groove rgb(229, 229, 229);
}

.cardsContainer {
    height: 50vh;
    width: fit-content;
    text-align: center;
    padding: 0;
    transition: all 0.1s ease-out;
    scroll-snap-align: center;
    border-radius: 10px;
    margin: 10px;
     overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #cce4e8;
}
@media(max-width:500px) {
    .cardsContainer {
        width: 80vw;
        margin: 10px;
    }

    .productsImage {
        height: 200px;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        margin: 0px auto;
        margin-top: 0px;
    }
}

.cardsContainer:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transform: scale(1.002);
    transition: .1s ease-in-out;

}

.dltedPrice {
    padding-left: 20px;
}

.productsImage {
    height: 30vh;
    padding: 25px;
    aspect-ratio: 1/1;
    object-fit: object-scale-down;
    border-radius: 10px;
    margin: 10px 0 5px 0;
}

.nameAndPrice {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
}

.desktopPadding {
    height: 20px;

}

.productName {
    margin-top: 5px;
    font-weight: 700;
    font-size: 1.5rem;
    text-align:start ;
    font-family: 'Montserrat', sans-serif;
    font-family: sans-serif;
    color: #000000;
}

.productName p {
    margin-bottom: 0 !important;
    font-family: 'Montserrat', sans-serif;
}
.productName span{
    font-size:0.9rem ;
    width: 95%;
    font-weight: 200;
}
.priceAndDiscount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.fa-solid {
    font-size: 19px;
}

.productPrice {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    align-items: center;
    justify-content: flex-end;
    color: black;
    margin: 0;
}

.productViewDetailBtn {
    margin-top: 4%;
    margin-bottom: 3%;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: .5px;
    color: rgb(2, 2, 63);
    padding-bottom: 10px;
}

.productViewDetailBtn:hover {
    color: rgb(5, 5, 117);
    text-decoration: underline;
}

.start-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 5px;
}

.cardsContainer span {
    margin-top: 10px;
}

.newProductImage {
    width: 2.5rem;
    position: absolute;
    right: 0;
    top: 0;
}

.priceProduct {
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: 700;
}

.productDiscription {
    background-color:transparent;
    /* height: 10rem; */

    /* margin-top: -20px; */
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    justify-content: flex-start;
}

.productBlank {
    /* height: 10rem; */
    width: 20px;
    background-color: aquamarine;
}

.productPriiceAndTitle {
    margin: 0 10px;
    width: 100%;
}

.discountTag {
    border: 0.5px solid rgba(0, 255, 187, 0.916);
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 2px 5px;
    color: #1a9377;
    overflow: hidden;
    white-space: nowrap;
    transition-duration: 0.5s;
    animation:
        typing 3.5s steps(40, end),
        blink-caret .75s ease-in-out infinite;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 60%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: rgb(0, 255, 195);
    }
}

.discountTag p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 700;
}

.discountTag p span {
    font-size: 0.8rem;
}

.mrpProduct {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
    font-style: italic;
}

@media (min-width:500px) {
    .productViewDetailBtn {
        padding-bottom: 20px;
    }

}

.productImageContainer {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cce4e8;
}
.BtnHomeProductsContiner{
    margin-left: auto;
    width: fit-content;
    padding:0 10px;
}
.BtnHomeProducts{
    padding: 6px;
    border: 1px solid rgb(149, 145, 145);
    border-radius: 100%;
    margin: 0 5px;
}
.BtnHomeProducts i{
    color: #127d7a;
}