.mainContainer {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 0.1px solid #cccccc;
    border-radius: 20px;
    margin: 20px;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.mainImage {
    height: 40vh;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.previewContainer {
    width: 100%;
    height: 10vh;
    display: flex;
    padding: 0 30px;
    justify-content: space-around;
}

.previewImage {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detailsContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 30px 10px 30px;
}