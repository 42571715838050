.Account{
    height: -moz-calc(100vh - 66px);
    height: -webkit-calc(100vh - 66px);
    height: -o-calc(100vh - 66px);
    height: calc(100vh - 66px);
    overflow:scroll ;
}
.mainAccountDiv{
    /* background: rgb(34,193,195);
    background: linear-gradient(180deg, rgba(34,193,195,0.7094188017003676) 100%, rgba(186,236,216,0.7122199221485469) 7%); */
    background: rgb(255, 255, 255);
    height: 100vh;
    overflow: hidden;
}
.box1{
    background: #1dbdc0;
    height: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.bottomAccountContainer{
    background-color: aliceblue;
    border-top-left-radius:20px ;
    border-top-right-radius:20px ;
    /* position: sticky;
    top:0px; */
    /* height:100%;
    width:100%; */
    margin-top: 20px;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: -moz-calc(100vh - 287px);
    height: -webkit-calc(100vh - 287px);
    height: -o-calc(100vh - 287px);
    height: calc(100vh - 287px);
    /* height: 66.43%; */
}
.topsection{
    font-size: 30px;
    color: rgb(68, 66, 66);
    letter-spacing: 5px;
    font-weight:600;
    padding-bottom: 0px;
}
.sidename{
    font-size: 30px;
    color: rgb(163, 159, 159);
    letter-spacing: 5px;
    font-weight:600;
}
.userimg1{
   width: 60px;
   height: 60px;
   padding: 4px;
   border-radius: 100%;
   /* background: rgba(225, 222, 222, 0.403); */
   /* box-shadow: 0px 2px 6px inset rgb(255, 3, 3,0.51); */
   /* backdrop-filter: blur(10px); */
}
.toBeVerified{
    box-shadow: 0px 2px 6px rgba(3, 255, 230, 0.581);
    padding: 4px;
}
.userimg{
    box-sizing: content-box;
}
#button{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.shadow{
    display: flex;
    justify-content: center;
}
#button button{
    background-color: rgb(58, 58, 241);
}

.topsection{
    font-size: 30px;
    color: rgb(68, 66, 66);
    letter-spacing: 5px;
    font-weight:600;
    padding-bottom: 40px;
}
.sidename{
    font-size: 30px;
    color: rgb(163, 159, 159);
    letter-spacing: 5px;
    font-weight:600;
}
/* .userimg1{
   width: 60px;
   height: 60px;
} */
#button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.shadow{
    display: flex;
    justify-content: center;
}
.sign-up {
    background-color: blueviolet;
}


option{
    margin-left: 5%;
    width: 500px;
}

.Header{
    font-size: 30px;
    font-weight: 800;
}
.editBtnBox{
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19px );
    -webkit-backdrop-filter: blur( 19px );
    padding:  1px 12px;
    border-radius: 25px;
    font-weight: 600;
    background: #1dbdc0;
    width: 9rem;
    text-align: center;
    letter-spacing: 2px;
    color: white;
    font-size: medium;
}
.accountBtnBox{
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: space-around;
}
.accountLink{
    text-decoration: none;
}
.EditBtnImg{
    height: 40px;
    width: 40px;
    margin: 0 auto;
}
.addBtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    background: #1dbdc0;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19px );
    -webkit-backdrop-filter: blur( 19px );
    padding: 1px 0;
    width: 9rem;
    border-radius: 20px;
    color: white;
    font-weight: 600;
}
.productListContainer{
    height:80%;
}
.productsListItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.422);
    transition-duration: 0.5s;
}
.prodDet a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    transition-duration: 0.5s;
}
.productsListItem img{
    width: 70px;
    height: 70px;
}
.editDoneBtn{
    display: flex;
    margin: 0 auto;
    padding:  0 8px;
    border-radius: 5px;
    border: 1px solid black;
}
.nameAccount{
    font-size: x-large;
    color: black;
    font-weight: 500;
    letter-spacing:0.6px;
    font-family: 'mulish';
}
.addProductBtn{
    background-color: rgba(74, 203, 205, 0.562);
    padding: 0 10px;
    border-radius: 5px;
    margin: 0 auto;
    font-size: large;
    font-weight: 500;
}
.productsList{
    margin-bottom: 0;
    overflow: scroll;
    height: 70%;
    padding-left: 0 !important;
}

.verfiyBtn{
    font-size: 1rem;
    background: rgba(244, 43, 12, 0.942);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19px );
    -webkit-backdrop-filter: blur( 19px );
    padding: 1px 0;
    width: 9rem;
    text-align: center;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verifiedBtn{
    font-size: 1rem;
    background: rgba(85, 73, 247, 0.749);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19px );
    -webkit-backdrop-filter: blur( 19px );
    padding: 1px 0;
    width: 9rem;
    text-align: center;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addProductBtn:hover{
    background-color: rgba(25, 66, 67, 0.562);
    color: black;
}
.productsListItem:hover{
    background-color: rgba(128, 128, 128, 0.611);
    transition-duration: 0.5s;
}
.verificationModalContainer{
    position: absolute;
    background-color: whitesmoke;
    margin: auto;
    overflow-y: scroll;
    z-index: 1040;
    width: 100%;
    height: 100%;
    bottom: 0;
}
.closeModalBtnContainer{
    height: fit-content;
    justify-content: end;
}
.closeModalBtn{
    display: flex;
    margin-left: auto;
    height: fit-content;
}
.closeModalBtn img{
    height: 1.5rem;
}
.accountBtnContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.EditAccount{
    height: 75%;
    overflow-y: scroll;
}
@media (max-width:300px) {
    .editBtnBox{
        font-size: small;
        padding: 0;
        margin: 0 px;
    }
    .addBtn{
        margin: 0 ;
        font-size: small;
        width: fit-content;
        padding: 0 25px;
    }
}
.logoOutBtnAccount img{
    width:2rem;
    margin-left: auto;
}
.logoOutBtnAccount{
    width: 100%;
}
.editAddProductModal{
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: 1200;
}
.verficationIcon{
    position: absolute;
    margin: 0;
}
.prodDet{
    width:50vw;
}
@media(max-width: 801px) {
    .account-anim {
        transform: translateY(100%);
        animation: moveup 0.5s forwards;
    }
    @keyframes moveup {
        to {
            transform: translateY(0);
        }
    }
}

