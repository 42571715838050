  /* css for the college modal section  */
  /* @import url(https://fonts.googleapis.com/css?family=Open+Sans); */

  /* .col {
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }
  .img-bor img {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    border: 0px solid black;
    width: 60px;
    height: 60px;
    object-fit:scale-down;
  }

  .img-bor {
    margin: 4%;
    width: 100px;
    height: 100px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 10px;
  }
  .collageModal{
    background: rgba( 2, 1, 1, 0.15 );
    backdrop-filter: blur( 11px );
    -webkit-backdrop-filter: blur( 11px );
    width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: auto;
    height: 100vh;
  }
 
  .img-bor:hover {
    border: 1px solid teal;
  }


  .wrap {
    margin: 4px;
  }

  .search {
    display: flex;
    justify-content: center;
  }

  .searchTerm {
    width: 35%;
    height: 50%;
    border-radius: 2px
  }

  .search {
    width: 100%;
    position: relative;
    display: flex;
  }

  .searchTerm {
    width: 35%;
    height: 50%;
    border-radius: 2px;
    border: 3px solid #00B4CC;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #9DBFAF;
  }

  .searchTerm:focus {
    color: #00B4CC;
  }

  .searchButton {
    width: 40px;
    height: 36px;
    border: 1px solid #00B4CC;
    background: #00B4CC;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }

  .modal {
    display: none;
    height: 250px;
    width: 90vw;
    margin: 0 auto;
  }

  .city {
    z-index: 15;
    background-color: white;
    margin-left: auto;
    height: fit-content;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    
  }

  .shadow-md {
    background: rgb(249, 247, 247);
  }
.city{
  width: 75vw;
  height: 75vh;
} */


  /* css for the college modal section  */

  .modalCollege {
    display: none;
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0,0,0,0.4);
    z-index: 10000;
  }
  
  /* Modal Content/Box */
  
  .CollegeModalContainerS {
    background-color: #fefefe;
    margin: 8% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 88vw; 
    height: 50vh;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
  .collegeCardContainerS{
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  .collegeCardContainerS img {
    margin-top: 1em;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    border: 0px solid black;
    width: 60px;
    height: 60px;
    object-fit:scale-down;
  }
  .collegesCardsContainerS{
    margin-top: 1em;
    display: flex;
    height: fit-content;
    gap:1em;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    color: teal;
    transform: translateX(0px);
    transition: all 0.5s ease-in-out;
    z-index: 1000;
  }
  .collegeCardContainerS p{
    margin-top: 1em;
    margin-left: 15px;
    font-size: 12px;
    letter-spacing: 2px;
  }
  .collegeCardContainerS:hover{
    border: 1px solid teal;
    border-radius: 4px;
  }
  
  /* css for the colleges modal in mobile view  */
  
  .mainCollegeModalContainerMobileS{
    padding: 2em 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5em;
    height: 100vh;
    background: #F5F7FA;
  }
  .mobileCollegeCardContainerS{
    height: 15vh;
    width: 30vw;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    border: 2px solid teal;
  }
  .mobileCollegeCardLogoS{
    border-radius: 50%;
    border: 1px solid teal;
    height: 70px;
    width: 70px;
    margin: 1em auto;
    object-fit:scale-down;
  }
  .mobileCollegeCardTitleS{
    font-size: larger;
    font-weight: 200;
    letter-spacing: 1px;
    margin-top: -0.75em;
    margin-left: auto;
    margin-right: auto;
    color: #888;
  }
