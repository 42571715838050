.listed-products-container{
    display: flex;
    flex-direction:row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-padding: 0 10%; 
    scroll-snap-type: x mandatory;
    padding: 16px 0px;
    margin-top: -10px;
    margin-left: 0px;
    width: 102%;
 
    
}
.list_header li{
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    font-size: large;
    color: rgb(75, 73, 73);
}
.categoriesName-container{
    margin-left: 6px;
}
.categories-holder{
    margin-top: 2%;
}
.products-list{
    /* width:100%;
    height: 100vh; */
    overflow: scroll;
    height: -moz-calc(100vh - 84px);
    height: -webkit-calc(100vh - 84px);
    height: -o-calc(100vh - 84px);
    height: calc(100vh - 84px);
}

.productpage-anim {
    transform: translateY(100%);
    height: -moz-calc(100vh - 84px);
    height: -webkit-calc(100vh - 84px);
    height: -o-calc(100vh - 84px);
    height: calc(100vh - 84px);
    animation: moveup 0.5s forwards;
}

@keyframes moveup {
    to {
        transform: translateY(0);
    }
}