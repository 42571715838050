/* css for the top carousel in events home page */

.sliderContainerEventsPage {
  margin-top: 2em;
  margin-bottom: 2em;
}

/* css for the event cards  */

.eventCardsContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 0.75em;
  font-family: "Montserrat", sans-serif;
  border-bottom: 0.5px solid teal;
  margin: 0 auto;
  margin-bottom: 1em;
}
.eventCardsImageContainer {
  width: 32vw;
}
.eventCardsImageContainer img {
  border-radius: 8px;
}
.eventCardsTextcontainer {
  width: 55vw;
  justify-content: center;
  color: black;
}
.eventCardsHeading {
  margin: 0 0 0 1em;
  font-size: 18px;
  font-weight: 700;
}
.eventCardsTextcontainer p {
  font-size: large;
  font-weight: 400;
  justify-content: center;
}
.eventCardsTextcontainer span {
  font-weight: 500;
  /* margin-left: 1em; */
  font-size: 12px;
}
.eventCardsDate {
  margin-right: 1em;
}
.eventCardsButton {
  width: 9em;
  background: white;
  border: none;
  margin-top: 1em;
  font-size: smaller;
  color: teal;
  text-align: left;
  margin-left: 1em;
}
/* .registerButtonContainer {
  margin: 0 4em 0.75em 3em;
} */
.eventCardsSection{
  margin-bottom: 6em;
}
.eventDateAndTimeContainer {
  margin: 0 0 0 1em;
}
/* css for the bottom carousel in events home page  */

.category {
  color: white;
  /* opacity: 0.7; */
  font-weight: 700;
  text-transform: "uppercase";
}
.card {
  height: 10em;
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
  align-items: "flex-start";
  background-size: "cover";
  background-position: "center";
}

.title {
  font-weight: 900;
  color: white;
  font-size: 14px;
}
.bottomCarouselEvents{
  margin-top: 2em;
  padding: 1em;
  margin-bottom: 2em;
}
.bottomCarouselTitle{
  margin-bottom: 0.25em;
}
.bottomCarouselEventsTitle{
  padding-bottom: 1em;
  color: black;
}
.bottomCarouselEventsButton{
  position: absolute;
  margin-top: 6em;
  background-color: aliceblue;
  padding: 0.5em;
}

/* category section of events  */

.eventSectionContainer{
  width: 100vw;
  background-color: #20b3aa;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding-right: 1em;
  overflow-y: scroll;
  padding: 1em 0;
  margin: 0 0 10px 0;
}
.eventCategoryName{
  border: 1px solid white;
  border-radius: 16px;
  font-size:small;
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
  padding: 0 0.5em;
}
.eventCategoryButton:active {
  background-color: #ccc; 
  color: #fff; 
  border: none; 
}

.eventCategoryButton:focus {
  background-color: #fff;
  color: #20b3aa; 
  /* border: 1px solid #ccc;  */
  outline: none; 
}
.eventsTitleContainer{
  font-weight: 700;
}
.eventsTitleContainer h2{
  padding-left:0.75em;
}
.eventsCategoryContainerHomePage{
  width: 100vw;
  height: max-content;
  padding: 1em 1em;
  display: grid;
  grid-template-columns:5em 5em 5em 5em;
  grid-template-rows: 2em 2em;
  row-gap: 1em;
  column-gap: 1em;
}

/* css for events category page  */

.eventsCategoryContainer{
  width: 100vw;
  margin: 0 1.5em;
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  color: black;
}
.eventsCategoryHeadingContainer{
  padding-bottom: 2em;
  margin-left: 1em;
}
.categoryContainerEvents{
  display: grid;
  grid-template-columns: 3em 3em 3em 3em;
  column-gap: 2.75em;
  row-gap: 1em;
   padding: 0 auto;
}
.categoryContainerEvents button{
  overflow: hidden;
  height: 3em;
  width: 6.5em;
  color: black;
  border: 1px solid black;
  border-radius: 16px;
  padding: 1em;
  text-overflow: ellipsis;
}

/* css for the modal in event section  */

.eventsModalContentContainer{
  z-index: 10;
  margin: 0 auto;
  overflow: auto;
  opacity: 1;
  width: 70vw;
  background-color: white;
  border: 1px solid teal;
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  animation: openmodal 0.3s forwards;
}
@keyframes openmodal {
  to {
    transform: translate(-50%, -50%);
  }
}
.event-modal-overlay {
  z-index: 5;
  height: 100vh;
  width: 100vh;
  overflow: hidden;
  filter: blur(4px);
}
.eventsModalPoster{
  margin: 0 1em;
  padding: 1em 0;
}
.eventModalPoster img{
  border-radius: 8px;
}
.eventsTimeDateContainer{
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding: 1em 0;
}
.eventModalTitle{
  margin: 0 0.6em;
}
.eventModalDescription{
  margin: 0 1em;
  padding-bottom: 0.5em;
}
.EventsCommingSoon{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
}
.EventsCommingSoon img{
  margin-top: auto;
  margin-bottom: auto;
}