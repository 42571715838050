@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --swiper-navigation-color: #000;
}
.mobile-view-contaier {
  height: -moz-calc(100vh - 100px);
  height: -webkit-calc(100vh - 100px);
  height: -o-calc(100vh - 100px);
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
/* CSS for mobile view */
.ProductDetails {
  width: 100vw;
  /* height: 50vh; */
  position: relative;
}
.back {
  background-color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.pricing {
  border: #313131;
  font-size: xx-large;
  border-top-left-radius: 23px !important ;
  position: relative;
  height: 6rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}
.pricing div span,
.pricing button div img {
  transform: translateY(-1rem);
}
.productname {
  background-color: #e1eaef;
  border: #e1eaef;
  color: white;
  font-weight: 400;
  font-size: xx-large;
  display: flex;
  align-items: center;
  border-top-left-radius: 23px !important ;
  z-index: 10;
  position: absolute;
  top: 4rem;
  height: 5rem;
  width: 100vw;
  padding-bottom: 1rem;
}
.productname span {
  margin-left: 1rem;
}

.product-description {
  background-color: #fff;
  border-top-left-radius: 23px;
  position: absolute;
  width: 100vw;
  top: 8rem;
  z-index: 15;
}

.product-description div {
  padding: 1rem 0 0 1rem;
}
.button-Container-Div {
  position: absolute;
  top: 17rem;
  z-index: 20;
  left: 0;
  right: 0;
}
.button-Container-Div a {
  align-items: center;
  letter-spacing: 0.2rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  z-index: 20;
  background: #127d7a;
}
/* .product-slide img {
    height: 50vh;
}




.mobile-view-contaier {
    margin-bottom: 12vh;
    width: 100vw;
} */

/* CSS for desktop view */
.half-underline {
  width: 3rem;
  border-top: 2px solid #dc3225;
}

.half-underline-gray {
  width: 3rem;
  border-top: 2px solid #dc3225;
}

.divide-line {
  width: 100%;
  border-bottom: 1px solid black;
  padding: 10px 0;
}

/* @media (min-width: 801px) {
    .swiper-button-prev {
        color: red; 
    }
    
    .swiper-button-next {
        color: #000; 
    }
} */
.swiper-pagination-bullet-active-main {
  color: rgba(255, 255, 255, 0.7);
}
.product-slider {
  width: 50%;
}

.button-Container-Div {
  display: flex;
  align-items: center;
  letter-spacing: 0.2rem;
  font-weight: 500;
  width: 80vw;
  margin: 0 auto 5px auto;
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  background: #127d7a;
}
.t {
  height: 100vh;
  width: 100vw;
  background-color: black;
}
.topopenbody {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.imageContainer {
  min-height: 300px;
  /* padding: 30px 20px; */
  background-color: white;
  min-width: 300px;
  border-radius: 4%;
}
/* New Details */
.Product-Detaials-container {
  position: relative;
  z-index: 12;
  background: #ffffff;
}
.Product-Detaials-Price-Share {
  background: #20b3aa;
  height: 3rem;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 45px 20px;
  /* position: absolute; */
  border-top-left-radius: 20px;
}
.Product-Detaials-Price-Share div:nth-child(1) {
  font-weight: 600;
  font-size: 1.3rem;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.Product-Detaials-Name {
  background: #127d7a;
  padding: 10px 20px 35px 20px;
  position: relative;
  transform: translateY(-6px);
  color: #fff;
  font-size: 1.4rem;
  margin-top: -10px;
  font-family: "Montserrat", sans-serif;
  border-top-left-radius: 20px;
}
.Product-Detaials-Desc {
  background-color: #fefefe;
  transform: translateY(-35px);
  position: relative;
  height: fit-content;
  border-top-left-radius: 20px;
  padding: 5px 20px;
  height: 4rem;
  z-index: 15;
}
/* End */
.animateS {
  animation: loadUp 0.5s ease-out;
}
@keyframes loadUp {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}
.animate1 {
  animation: loadUp_one 0.4s ease-out;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes loadUp_one {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-15%);
    opacity: 1;
  }
}
.animate2 {
  animation: loadUp_two 0.3s ease-out;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes loadUp_two {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-15%);
    opacity: 1;
  }
}
.animate3 {
  animation: loadUp_three 0.3s ease-out;
  opacity: 0;
  animation-fill-mode: forwards;
}
.animateS_four {
  animation: loadUp_four 0.3s ease-out;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes loadUp_three {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-35px);
    opacity: 1;
  }
}
@keyframes loadUp_four {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(4px);
    opacity: 1;
  }
}
.swiperContainer {
  height: 50vh;
}
