/* css for the navbar of the waitlist  */

.navbarContainerWaitlistS {
    height: 60px;
    width: 100vw;
    padding-top: 8px;
    text-align: center;
    color: #127d7a;
    font-size: x-large;
    font-weight: 700;
    text-shadow: 8px white;
    background: rgba(255, 255, 255, 0.45);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

/* css for the waitlist containing the state city colleges field and the verification fields  */

.main-fields-container-waitlist{
    /* margin: 1em 2em; */
    color: teal;
    font-size: larger;
    font-weight: 500;
    /* padding: 1em; */
}
.state-field-container-waitlist{
    margin: 0 auto;
    padding: 0.5em;
    /* width:10em; */
}
.city-field-container-waitlist{
    margin: 0 auto;
    padding: 0.5em;
    /* width: 10em; */
}
.college-field-container-waitlist{
    margin: 0 auto;
    padding: 0.5em;
    /* width:10em; */
}
.CollegeHeading{
  font-size: 2rem;
  color: #178d85;
  text-align: center;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.waitlistLabel{
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
}
.BtnWaitlist{
    margin: 1em auto;
    padding: 0.4em 2em;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    border:solid 1px rgb(255, 255, 255);
    background: linear-gradient(90deg,#178d85,#4fbcc0);
    border-radius: 10px;
    color:#fff;
    width:max-content;
}
.BtnContainerWaitlist{
    text-align: center;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}
.css-liqwddr-control{
  border: none !important;
  box-shadow: none !important;
}
/* .city,
.state,
.college{
  border:2px solid #cee9ef;
  border-radius: 10px;
  box-shadow: none;
} */
.city:focus-visible{
  outline: none;
  border: none;
}
/* css for the verification fields  */

.mainVerificationContainer{
    margin: 1em 5.5em;
    text-align: center;
}
.verificationFieldContainer
{
    color: teal;
    font-size: larger;
    font-weight: 600;
    margin-bottom: 1em;
    padding: 0.5em;
}
.verificationFieldContainer input{
    border:0;
    border-bottom:solid 2px teal;
    outline: none;
    caret-color: teal;
    color: teal;
}
/* Wait List CSS */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;400&display=swap');
.CircularProgressbar-trail{
    stroke: #cee9ef !important;
}
.CircularProgressbar-path {
  stroke: #127d7a!important;
}
.WaitList{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction:column ;
}
.WaitList-Counter{
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  width: 95vw;
  border-radius: 10px;
  background: url(../../../assets/bg.svg);
  background-size:cover ;
  background-repeat: no-repeat;
  justify-content: space-around;
  align-items: center;
}
.Waitlist{
  height: 60vh;
  width: 95vw;
}
.CommingSoon{
  height: 40vh;
  width: 95vw;
}
.WaitList-Counter div:nth-child(1){
  font-weight: 700;
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}
.WaitList-Counter div:nth-child(3){
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
}
.WaitList-Counter div:nth-child(3) span:nth-child(1){
  font-weight: 700;
  font-size: 1.8rem;
  text-align: center;
  margin: 0 auto;
  color: #fff;
}
.WaitList-Counter div:nth-child(3) span:nth-child(3){
  text-align: center;
  padding: 0 10px;
  color: #fff;
}
.WaitList-Counter-OnBoard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 85vw;
}
.WaitList-Counter-OnBoard span{
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
#triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #fff;
}
.WaitList-Counter div:nth-child(2){
  font-weight: 600;
  padding: 10px 0 10px 0 ;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}
.WaitList-Share-Conatiner{
    width: 100%;
}
.WaitList-Share{
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  background: linear-gradient(270deg,#ffffff,#ffffff);
  width:fit-content;
  margin: 0 auto ;
  border-radius: 40px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  padding: 4px 40px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
}
.WaitList-Share img{
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.VerificationModal-Container{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 12;
  background: #fff;
  top: 0;
}
.WaitList-Counter-ComingSoon{
  font-size: 1.9rem !important;
  font-family: "Montserrat", sans-serif ;
  font-weight: 700;
  color: #127d7a;
}
.addCollege{
  margin: 0 auto;
  display: flex;
  padding: 5px 10px;
  border-radius: 10px;
  width: 95vw;
  border: 1px solid gray;
  outline: none;
}
.backBtnNav{
  margin-right: auto;
  margin-top: 9px;
  font-size: 1rem;
  margin-left: 5px;
  color: #127d7a;
}