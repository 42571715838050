/* css for the category cards and the div rendering all the cards  */
.LazyLoad {
    opacity: 0;
    transition: all 1s ease-in-out;
  }
  
  .is-visible {
    opacity: 1;
  }

.category_CardRenderingS{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: fit-content;
    gap: 1em;
    justify-content:space-evenly;
    margin: 4em 4em;
}
.categoryCardsContainerS{
    height: fit-content;
    width:10em;
    border-radius: 5px;
    padding: 5px;
    border: 1.5px solid #20b3aa;
    transition: 0.5s;
    text-align: center;
}
.categoryCardsContainerS:hover{
    color: black;
    box-shadow:#20b3a96a 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.categoryCardsContainerS .categoryImgS{
    height: 2.5em;
    width: 2.5em;
    margin: 12px auto;
}
.categoryCardsContainerS .categoryTitleS{
    font-size: 1em;
    margin: 0 auto;
}
.linkTagS{
    text-decoration: none;
    
    color: black;
}
@media (max-width:992px) and (min-width:767px)
{
    .category_CardRenderingS{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 4em 2em;
        height: fit-content;
    }
    .categoryCardsContainerS{
        height:fit-content;
        width: 20vw;
    }
    .categoryCardsContainerS .categoryImgS{
        height: 1.75em;
        width: 1.75em;
        margin: 2em auto;
    }
    .categoryCardsContainerS .categoryTitleS{
        font-size: 0.75em;
        margin: 0 auto;
        margin-top: -2em;
    }
}
@media (max-width:768px) and (min-width:280px)
{
    .category_CardRenderingS{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 2em 2em;
        height: fit-content;
    }
    .categoryCardsContainerS{
        height:fit-content;
        width: 20vw;
    }
    .categoryCardsContainerS .categoryImgS{
        height: 1.5em;
        width: 1.5em;
        margin: 12px auto;
    }
    .categoryCardsContainerS .categoryTitleS{
        font-size: 0.75em;
        margin: 0 auto;
    }
}