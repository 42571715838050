.chatInfo {
    position: sticky;
    width: 100vw;
    height: fit-content;
    background: #127d7a;
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.userNameChat{
    font-weight: 600;
    color: #fff;
    font-size: larger;
    font-family: "Montserrat", sans-serif;
    
}

.btnPrevChat {
    border: none;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.chatInfo img {
    height: 40px;
    width: 40px;
    margin-left: 5px;
    border: 1.5px solid rgb(150, 150, 150);
    border-radius: 100%;
}

.Send-input {
    display: flex;
    width: 100vw;
    background: rgba(255, 255, 255, 0.2);
    align-items: center;
    padding: 10px 0;
}

.inputBox{
    width: 95vw;
    border-radius: 20px;
    padding: 6px 10px;
    border: 1px solid #127d7a;
    margin: 0 auto;
}

.inputBox:focus-visible {
    border: 0.5px solid rgb(0, 177, 177);
    outline: none;
}

.messages {
    height: -moz-calc(100vh - 210px);
    height: -webkit-calc(100vh - 210px);
    height: -o-calc(100vh - 210px);
    height: calc(100vh - 210px);
    /* position: fixed;
    top: 5vh; */
    width: 100vw;
    overflow-y: scroll;
}

/* @media (max-width:420px) and (max-height:668px){
    .messages{
        height:32.4rem;
    }

} */
.singleMessage {
    margin: 0;
}

.messageBox {
    height: 32.5rem;
    overflow: hidden;
}

.message {
    padding-top: 1vh;
    padding-bottom: 1vh;
    /* margin-right: 1vw; */
    margin-right: auto;
    margin-left: 0;
    width: fit-content;
    max-width: 65%;
    word-wrap: break-word;
}

.messageowner {
    background-color: rgb(92, 233, 233);
    padding: 0.4rem 1.5rem;
    border-radius: 10px 0px 10px 10px;
}
.messagereciver{
    background-color: rgb(237, 230, 230);
    padding: 0.4rem 1.5rem;
    border-radius: 0px 10px 10px 10px;
    margin-left: 10px;
}

.messagefalse {
    background-color: rgb(190, 191, 192);
    padding: 0.4rem 1.5rem;
    border-radius: 0 10px 10px 10px;
}

.send {
    margin-left: auto;
    border-radius: 100%;
    background: #127d7a;
    height: 2.1rem;
    width: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3.5%;
}
.send img{
    width: 1.2rem;
    height: 1.2rem;
    /* margin-right: 2px; */
}
.owner {
    margin-left: auto;
    margin-right: 0;
    padding-right: 1.5vw;
}

.false {
    margin-right: auto;
    padding-left: 1.5vw;
}
.product-Info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #127d7a;
    font-family: "Montserrat", sans-serif;
    color: #fff;
}
.productPrice{
    color: #fff;
}