.AddProduct-Modal-container{
    height: 100vh;
    width: 100vw;
    background: rgb(255, 255, 255);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
}
.AddProduct-Modal-Close{
    width: fit-content;
    margin-left: auto;
    position: absolute;
    right: 0;
    top: 0;
}
.AddProduct-Modal-Close span img{
    width: 25px;
    height: 25px;
    margin-right: 5px;
    margin-top: 5px;
}

.AddProduct-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #17948b;
}
.AddProduct-heading span{
    font-size: 2rem;
    font-weight: 700;
    margin: 0 auto;
}
.AddProduct-form{
    margin-top: 15px;
    height: -moz-calc(100vh - 150px);
    height: -webkit-calc(100vh - 150px);
    height: -o-calc(100vh - 150px);
    height: calc(100vh - 150px);
    overflow-y: scroll;
}
.AddProduct-form form{
    width:95vw;
    display: flex;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
}
.AddProduct-form form div:nth-child(6){
    margin-top: 5px;
    margin-bottom: 10px;
}
.Addproduct-input-container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 95vw;
    margin:0 10px ;
}
.Addproduct-input-container input{
    /* width: 90vw; */
    border-bottom: 1px solid #1EBAAF;
    /* border-radius: 5px; */
    padding: 3px 15px;
    transition-duration: 0.5s;
    margin-bottom: 20px;
}
.Addproduct-input-container label img{
    height: 40px;
    width: 40px;
}
.Addproduct-input-container input:focus-visible{
    outline: none;
    border: 1px solid #1EBAAF;
    transform: scale(1.01);
    border-radius: 5px;
    box-shadow: 0 0.5px 5px #1EBAAF ;
}
.Addproduct-input-new{
    display: flex;
    justify-content: flex-start;
}
.Addproduct-input-new input{
    width: fit-content;
    margin-bottom: 0;
    /* outline: #12e0c8;
    border: #12e0c8 solid 1px; */
}
.Addproduct-input-new label{
    margin-bottom: 0;
    margin-left: 5px;
    color: #17948b;
}
.Addproduct-input-old label{
    margin-bottom: 0;
    margin-left: 5px;
    color: #17948b;
}
.Addproduct-input-old{
    display: flex;
    justify-content: flex-start;
}
.Addproduct-input-old-btn{
    width: 10px;
    border-radius: 0;
}
.Addproduct-input-old input{
    width: fit-content;
    margin-bottom: 0;
}
.Addproduct-input-container select{
    width: 70vw;
    border: 1px solid #1EBAAF;
    border-radius: 5px;
    padding: 3px 10px;
    margin-bottom: 15px;
}

.addProduct-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.addProduct-input-container {
    position: relative;
}

.addProduct-input-container input {
    border: solid 1.5px #02c1a1;
    width: 95vw;
    border-radius: 1rem;
    margin-top: 10px;
    background: none;
    padding: 0.8rem;
    font-size: 1rem;
    color: #000000;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.Addproduct-input-container textarea{
    border: solid 1.5px #02c1a1;
    width: 95vw;
    border-radius: 1rem;
    margin-top: 40px;
    background: none;
    padding: 0.8rem;
    font-size: 1rem;
    color: #000000;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.addProduct-Desc-Lable{
    transform: translateY(-350%) translateX(13px) scale(0.9);
    background-color: rgb(255, 255, 255);
    padding: 0 0.5em;
    width: fit-content;
    color: #000000;
    font-weight: 200;
}
.addProduct-Lable{
    position: absolute;
    left: 15px;
    color: #0c0c0c;
    font-weight: 400;
    pointer-events: none;
    transform: translateY(1.5rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.addProduct-input-container input:focus,
.addProduct-input-container input:valid
{ 
    outline: none;
    border: 1.5px solid #005429;
}
.addProduct-input-container input:focus ~.addProduct-Lable,
.addProduct-input-container input:valid ~.addProduct-Lable{
    transform: translateY(-12%) scale(0.9);
    background-color: rgb(255, 255, 255);
    padding: 0 .2em;
    color: #000000;
}
.Addproduct-submit{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Addproduct-submit-btn{
    background: linear-gradient(45deg,#178d85,#4fbcc0);
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    border-radius: 10px;
}
.condditionLable{
    font-size: 1.1rem;
    font-weight: 500;
}
.categoryLable{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 5px;
}
.categorycontainer{
    margin-top: 10px;
}
.counterWord{
    font-size: small;
    color: grey;
    transform: translateY(-95%) translateX(10px);
}
.closeModal{
    padding: 5px 10px;
    background: #fff;
    color: #02c1a1;
    border: 1px solid #17948b;
    border-radius: 10px;
    margin-left:15px ;
}
.previewImage{
    width: 5rem;
    height: 5rem;
    border: 1px dashed gray;
}
