
.desktopcategorynavbar{
    display : flex;
    width: 100vw;
    height : 7vh;
    position: fixed;
    z-index: 2;
    align-items: center;
    background-color: #efeeee;
    border-bottom: 1px solid #e6e6e6;
    justify-content: space-between;
}
.path{
    /* padding-top: 22px; */
    display: flex;
}
.path a{
    text-decoration: none;
    color: #717171;
    margin-left: 3.5vw;
    margin-right: 1.5vw;
    font-size: 17px;
}
.path p{
    font-size: 17px;
}
.nexticon{
    height: 0;
    padding-top: 11px;
}
.othercategories a{
    text-decoration: none;
    color: #0b0b0b;
    font-weight: 600;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    font-size: 17px;
    font-weight: 50;
    letter-spacing: 2px;
}
.othercategories .active{
    border-bottom:2px solid black ;
}
.activeCategory{
    border-bottom:2px solid  grey;
    color: rgb(20, 78, 62);
}