/* Css for the navbar  */

.mainContainerS {
    max-width: 100vw;
    height: 100%;
    background-color: white;
    margin: 0 auto;
    border-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 1300;
}

.logoTitleContainerS {
    float: left;
    padding: 0 50px;
    align-items: center;
}

.logo_S {
    height: 45px;
    width: 45px;
    padding-right: 8px;
    position: absolute;
    top: 8px;
}

.logoTitleContainerS .titleS {
    position: absolute;
    left: 100px;
    top: 5px;
    text-decoration: none;
    color: teal;
    font-weight: 900;
    font-family: 'Mulish', sans-serif;
    font-size: 2em;
    text-align: center;
}

.navList {
    width: auto;
    height: auto;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    gap: 25px;
    float: right;
    margin-right: 8%;
    margin-top: 10px;
}

.listContainerS ul li {
    list-style: none;
}

.listContainerS ul li button {
    border: none;
    background: white;
    color: teal;
    font-size: 1em;
    font-family: 'Mulish';
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s;
    height: 35px;
    letter-spacing: 0.1em;
    padding: 4px;
    border-radius: 8px;
}

.clearFix {
    clear: both;
}

.listContainerS ul li button.activeS {
    background-color: teal;
    color: white;
    border-radius: 8px;
}

.listContainerS ul li button.activeS:hover {
    background-color: white;
    border: 1px solid teal;
    color: teal;
}

.listContainerS ul li button:hover {
    background-color: teal;
    color: white;
    border-radius: 8px;
}

.collegeModalS {
    z-index: 10;
    position: absolute;
    right: 150px;
    top: 50px;
    display: flex;
    border: 1px solid #ccc;
}